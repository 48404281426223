import React, { useState, useCallback } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

function PuzzleInputText({ solution, hint1, checkSolution }) {
  const [inputValue, setInputValue] = useState('');
  const [feedback, setFeedback] = useState(null);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const matchedSolutionIndex = solution.findIndex(
      solutionGroup => solutionGroup.some(
        sol => sol.trim().toLowerCase() === inputValue.trim().toLowerCase()
      )
    );

    if (matchedSolutionIndex !== -1) {
      setFeedback({ type: 'success', message: 'Correct answer!' });
      checkSolution(matchedSolutionIndex);
    } else {
      setFeedback({ type: 'danger', message: hint1 || 'Wrong answer, try again!' });
    }
  }, [inputValue, solution, hint1, checkSolution]);

  const isInputEmpty = inputValue.trim() === '';

  return (
    <Form onSubmit={handleSubmit} style={{ minHeight: '150px' }}>
      <Form.Group controlId="textPuzzleInput">
        <Form.Label>Enter your answer:</Form.Label>
        <Form.Control
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type your answer here"
          className="mb-2"
          style={{ height: '40px' }}
        />
      </Form.Group>
      
      <Button variant="primary" type="submit" className="mt-3" disabled={isInputEmpty}>
        Antwort absenden
      </Button>

      {feedback && (
        <Alert variant={feedback.type} className="mt-3">
          {feedback.message}
        </Alert>
      )}
    </Form>
  );
}

export default PuzzleInputText;