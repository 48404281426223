import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { WalkDataContext } from '../hooks/walkDataContext';
import QuestListElement from './QuestListElement';
import QuestDetails from './QuestDetails';
import { ListGroup, Spinner, Alert, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function Quests() {
  const { 
    quests, 
    contentState, 
    getElementState, 
    language, 
    setElementState,
    expandedQuest, 
    setExpandedQuest 
  } = useContext(WalkDataContext);
  const [renderedQuestsInList, setRenderedQuestsInList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedQuest, setSelectedQuest] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [activeQuests, setActiveQuests] = useState([]);

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  useEffect(() => {
    if (!contentState || !quests) {
      console.log('Quests or Content state not available yet.');
      return;
    }

    const activeQuestsList = quests.filter(quest => {
      const state = getElementState('quest', quest.slug);
      return state === 'active' || state === 'finished';
    });

    setActiveQuests(activeQuestsList);



    const questsListToRender = activeQuestsList.map((quest, index) => (
      <QuestListElement 
        key={index}
        quest={quest}
        state={getElementState('quest', quest.slug)} 
        contentState = {contentState}
        language={language}
        setExpandedQuest={setExpandedQuest}
        handleCloseModal={handleCloseModal}
        

      />
    ));

    setRenderedQuestsInList(questsListToRender);
    setLoading(false);

    if (expandedQuest) {
      const quest = activeQuestsList.find(q => q.slug === expandedQuest);
      setSelectedQuest(quest || activeQuestsList[0]);
    } else if (activeQuestsList.length > 0) {
      setSelectedQuest(activeQuestsList[0]);
    }
  }, [contentState, quests, getElementState, language, expandedQuest, setExpandedQuest]);

  const navigateQuest = useCallback((direction) => {
    const currentIndex = activeQuests.findIndex(q => q.slug === selectedQuest.slug);
    let newIndex;
    if (direction === 'prev') {
      newIndex = (currentIndex - 1 + activeQuests.length) % activeQuests.length;
    } else {
      newIndex = (currentIndex + 1) % activeQuests.length;
    }
    setSelectedQuest(activeQuests[newIndex]);
    setExpandedQuest(activeQuests[newIndex].slug);
  }, [activeQuests, selectedQuest, setExpandedQuest]);

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartY(e.touches[0].pageY - containerRef.current.offsetTop);
    setScrollTop(containerRef.current.scrollTop);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const y = e.touches[0].pageY - containerRef.current.offsetTop;
    const walk = (y - startY) * 2;
    containerRef.current.scrollTop = scrollTop - walk;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };



  if (loading) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    
    <div
      ref={containerRef}
      style={{
        height: '100vh',
        overflow: 'auto',
        touchAction: 'none'
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >

<div className="d-flex justify-content-between align-items-center mb-4">
  <div className="d-flex align-items-center">
    {activeQuests.length > 1 && (
      <FontAwesomeIcon
        icon={faChevronLeft}
        className=" mr-150 cursor-pointer"
        onClick={() => navigateQuest('prev')}
      />
    )}
    {selectedQuest && (
      <h2 className="mb-0">
        {selectedQuest.metadata.questMeta_title[language]}
      </h2>
    )}
    {activeQuests.length > 1 && (
      <FontAwesomeIcon
        icon={faChevronRight}
        className="ml-150 cursor-pointer"
        onClick={() => navigateQuest('next')}
      />
    )}
  </div>
  <Button variant="outline-primary" size="sm" onClick={handleShowModal}>
    Show Quests
  </Button>
</div>

      {selectedQuest && (
        <QuestDetails quest={selectedQuest} language={language} />
      )}

      <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Available Quests</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {renderedQuestsInList.length > 0 ? (
            <ListGroup variant="flush">
              {renderedQuestsInList}
            </ListGroup>
          ) : (
            <Alert variant="warning">No quests available.</Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className='miniplayer-spacer'></div>
      <div className='miniplayer-spacer'></div>
      <div className='navmenu-spacer'></div>
    </div>
  );
}

export default Quests;