import React, { useEffect, useState, useContext } from 'react';
import { Card, Image, ListGroup, Alert, Button, Modal } from 'react-bootstrap';
import LazyLoad from 'react-lazyload';
import Puzzles from './Puzzles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBackward, faForward, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { WalkDataContext } from '../hooks/walkDataContext';

function QuestDetails({ quest, language }) {
  const { contentState, getElementState , setCurrentTrack} = useContext(WalkDataContext);
  const questDescription = quest.metadata.questMeta_description?.[language] ?? null;
  const questIllustration = quest.metadata.questMeta_illustration?.[language] ?? null;
  const questAudio = quest.metadata.questMeta_audio?.[language] ?? null;
  const questTitle = quest.metadata.questMeta_title?.[language] ?? null;

  const [renderedPuzzles, setRenderedPuzzles] = useState([]);
  
  const [loading, setLoading] = useState(true);
  const [showImageModal, setShowImageModal] = useState(false);

  const handleImageClick = (e) => {
    e.stopPropagation(); // Verhindert, dass der Klick die Karte aufklappt/zuklappt
    setShowImageModal(true);
  };

  //console.log(questAudio);

  const puzzles = quest.puzzles ?? [];

  // berücksichtigt noch keinen Sprachwechsel
  const setExternalAudio = () => setCurrentTrack({
    'position':99,
    'slug':'temp2', 
    'metadata': {
      'trackMeta_audiotrack':{
        '2':questAudio
      },
      //'trackMeta_author':{'1':''},
      'trackMeta_cover':{'2':questIllustration ? questIllustration : '/assets/illustrations/placeholder-cover.png'},
      'trackMeta_name':{'2': questTitle}
    }
  })

  useEffect(() => {
    setLoading(true);
    setRenderedPuzzles([]); // Reset puzzles when quest changes

    if (!contentState || puzzles.length === 0) {
      console.log('Puzzles or Content state not available yet.');
      setLoading(false);
      return;
    }

    const puzzlesToRender = puzzles.map((puzzle, index) => {
      const state = getElementState('puzzle', puzzle.slug);
      if (state === 'active' || state === 'finished') {
        return (
          <Puzzles
            key={puzzle.slug}
            puzzle={puzzle}
            state={state}
            language={language}
          />
        );
      }
      return null;
    }).filter(Boolean);
    setRenderedPuzzles(puzzlesToRender);
    setLoading(false);
  }, [contentState, puzzles, getElementState, language, quest]);
  

  return (
    <>
    <Card className="mb-4">
      <Card.Body>
        <div className="quest-content">
          <div className="quest-info">
            {questIllustration && (
              <div className="quest-illustration">
                <Image 
                  src={questIllustration} 
                  onClick={handleImageClick}
                />
              </div>
            )}
            {questDescription && (
              <div className="quest-description">
                <div dangerouslySetInnerHTML={{ __html: questDescription }} />
                {questAudio && (
                  <div className="mt-2">
                    <Button onClick={() => setExternalAudio()} variant="outline-secondary" size="sm">
                      <FontAwesomeIcon icon={faPlay} />
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
          
          <div className="puzzle-list mt-4">
            {loading ? (
              <Alert variant="info">Loading puzzles...</Alert>
            ) : renderedPuzzles.length > 0 ? (
              <ListGroup variant="flush">
                {renderedPuzzles}
              </ListGroup>
            ) : (
              <Alert variant="warning">No puzzles available for this quest.</Alert>
            )}
          </div>
        </div>
      </Card.Body>
    </Card>
        <Modal 
        show={showImageModal} 
        onHide={() => setShowImageModal(false)} 
        size="xl" 
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Image 
            src={questIllustration} 
            style={{
              width: '100%',
              height: '80vh',
              objectFit: 'contain'
            }}
          />
        </Modal.Body>
      </Modal>
      </>
  );
}

export default QuestDetails;