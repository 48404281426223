import React, { useMemo, useContext, useState, useEffect, useRef } from 'react';
import { BsMusicNoteBeamed } from 'react-icons/bs';
import { WalkDataContext } from './hooks/walkDataContext';
import AudioEffectControls from './audioControllers/tonePlayer/AudioEffectControls';

const AudioLab = ({ handleTrack }) => {
  const { tracks, currentTrack, storyMetas, language, updateAudioEffect, audioEffects, setCurrentTrack, contentState } = useContext(WalkDataContext);
  const [currentTrackSlug, setCurrentTrackSlug] = useState(null);
  const playlistRef = useRef(null);

  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartY(e.touches[0].pageY - containerRef.current.offsetTop);
    setScrollTop(containerRef.current.scrollTop);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    const y = e.touches[0].pageY - containerRef.current.offsetTop;
    const walk = (y - startY) * 2;
    containerRef.current.scrollTop = scrollTop - walk;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    setCurrentTrackSlug(currentTrack.slug);
  }, [currentTrack]);

  const renderedTracks = useMemo(() => {
    const activeTracks = contentState.filter(item => item.type === 'track' && item.state === 'active').map(item => item.slug);
    
    return tracks
      .filter(track => activeTracks.includes(track.slug) && !track.metadata.trackMeta_radio?.[1])
      .map((track) => {
        const isHighlighted = contentState.some(item => 
          item.type === 'track' && 
          item.slug === track.slug && 
          item.highlight === true
        );
  
        return (
          <button
            key={track.slug}
            onClick={() => handleTrack(track.slug)}
            className={`list-group-item list-group-item-action d-flex align-items-center ${track.slug === currentTrackSlug ? 'active' : ''}`}
          >
            <div className="playlist-thumbnail-container" style={{ position: 'relative' }}>
              <div className="playlist-thumbnail">
                {(track.metadata.trackMeta_cover && track.metadata.trackMeta_cover[language]) ||
                (storyMetas.storyMeta_cover && storyMetas.storyMeta_cover[language]) ? (
                  <img 
                    className="audio-image-small" 
                    src={
                      (track.metadata.trackMeta_cover && track.metadata.trackMeta_cover[language]) || 
                      (storyMetas.storyMeta_cover && storyMetas.storyMeta_cover[language]) || 
                      "/assets/illustrations/placeholder-cover.jpg"
                    } 
                    alt="audio avatar" 
                    onError={(e) => {
                      e.target.onerror = null; 
                      e.target.src = "/assets/illustrations/placeholder-cover.jpg";
                    }}
                  />
                ) : (
                  <img 
                    className="audio-image-small" 
                    src="/assets/illustrations/placeholder-cover.jpg" 
                    alt="Cover" 
                  />
                )}
              </div>
              {isHighlighted && (
                <div 
                  style={{
                    position: 'absolute',
                    top: '0px',
                    right: '0px',
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                    zIndex: 1
                  }}
                />
              )}
            </div>
            <div className="flex-grow-1">
              {track.metadata.trackMeta_name ? track.metadata.trackMeta_name[language] : 'Unbenannter Track'}
            </div>
          </button>
        );
      });
  }, [tracks, currentTrackSlug, language, contentState, storyMetas, handleTrack]);

  return (
    <div
    ref={containerRef}
    style={{
      height: '100vh',
      overflow: 'auto',
      touchAction: 'none'
    }}
    onTouchStart={handleTouchStart}
    onTouchMove={handleTouchMove}
    onTouchEnd={handleTouchEnd}
  >
      <div className='title-page'><h2>Audio Lab</h2></div>

      <button disabled={audioEffects.playbackRate === 1} onClick={() => updateAudioEffect('playbackRate', 1)}>
        Reset Play Rate
      </button>

      <AudioEffectControls effectName="isReversed" label="Reverse Audio" type="toggle" />
      <AudioEffectControls effectName="playbackRate" label="Playback Rate" type="slider" min={0.1} max={5} step={0.01} />
      <AudioEffectControls effectName="reverbWet" label="reverb wet" type="slider" min={0} max={1} step={0.01} />
      <AudioEffectControls effectName="reverbDecay" label="reverb decay" type="slider" min={0.001} max={10} step={0.01} />
      <AudioEffectControls effectName="isPingPongDelayEnabled" label="Ping Pong Delay" type="toggle" />
      
      <div 
        className="playlist-wrapper list-group"
      >
        {renderedTracks}
      </div>
      <div className='miniplayer-spacer'></div>
      <div className='miniplayer-spacer'></div>
      <div className='navmenu-spacer'></div>
      <div className='miniplayer-spacer'></div>
      <div className='miniplayer-spacer'></div>
      <div className='navmenu-spacer'></div>
    </div>
  );
};

export default AudioLab;