import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';

const Radio = ({ tracks, handleTrack, language }) => {
  const [digits, setDigits] = useState(['0', '0', '0', '0']);
  const [message, setMessage] = useState('');

  const handleDigitChange = (index, value) => {
    const newValue = value.replace(/[^0-9]/g, '');
    if (newValue.length <= 1) {
      const newDigits = [...digits];
      newDigits[index] = newValue || '0';
      setDigits(newDigits);
    }
  };

  const incrementDigit = (index) => {
    const newDigits = [...digits];
    newDigits[index] = newDigits[index] === '9' ? '0' : String(Number(newDigits[index]) + 1);
    setDigits(newDigits);
  };

  const decrementDigit = (index) => {
    const newDigits = [...digits];
    newDigits[index] = newDigits[index] === '0' ? '9' : String(Number(newDigits[index]) - 1);
    setDigits(newDigits);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const number = parseFloat(digits.join(''));
    
    const matchingTrack = tracks.find(track => {
      const radioValue = parseFloat(track.metadata?.trackMeta_radio?.["1"]);
      return !isNaN(radioValue) && radioValue === number;
    });

    if (matchingTrack) {
      handleTrack(matchingTrack.slug);
      setMessage('Track gefunden und wird abgespielt.');
    } else {
      const negativeTracks = tracks.filter(track => {
        const radioValue = parseFloat(track.metadata?.trackMeta_radio?.["1"]);
        return !isNaN(radioValue) && radioValue < 0;
      });

      if (negativeTracks.length > 0) {
        const randomTrack = negativeTracks[Math.floor(Math.random() * negativeTracks.length)];
        handleTrack(randomTrack.slug);
        setMessage('Kein passender Track gefunden. Ein zufälliger Track wird abgespielt.');
      } else {
        setMessage('Kein passender Track gefunden und keine Tracks mit negativem Radio-Wert verfügbar.');
      }
    }
  };

  return (
    <>
      <div className='title-page'><h2>Radio</h2></div>
      <Form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-center mb-3">
          {digits.map((digit, index) => (
            <div key={index} className="mx-2 text-center">
              <Button variant="outline-secondary" size="sm" onClick={() => incrementDigit(index)}>
                <FaChevronUp />
              </Button>
              <Form.Control
                type="text"
                value={digit}
                onChange={(e) => handleDigitChange(index, e.target.value)}
                className="text-center my-2"
                style={{ width: '40px' }}
              />
              <Button variant="outline-secondary" size="sm" onClick={() => decrementDigit(index)}>
                <FaChevronDown />
              </Button>
            </div>
          ))}
        </div>
        <div className="text-center">
          <Button variant="primary" type="submit">
            Abspielen
          </Button>
        </div>
      </Form>
      {message && <Alert variant="info" className="mt-3">{message}</Alert>}
    </>
  );
};

export default Radio;