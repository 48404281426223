import React, { useContext } from 'react';
import { WalkDataContext } from '../../hooks/walkDataContext';

const AudioEffectControls = ({ 
  effectName, 
  label, 
  type, 
  min, 
  max, 
  step 
}) => {
  const { audioEffects, updateAudioEffect } = useContext(WalkDataContext);

  const handleChange = (value) => {
    if (type === 'slider') {
      updateAudioEffect(effectName, parseFloat(value));
    } else if (type === 'toggle') {
      updateAudioEffect(effectName, !audioEffects[effectName]);
    }
  };

  if (type === 'slider') {
    return (
      <div>
        <input
          type="range"
          min={min}
          max={max}
          step={step}
          value={audioEffects[effectName]}
          onChange={(e) => handleChange(e.target.value)}
        />
        <span>{label}: {audioEffects[effectName].toFixed(2)}</span>
      </div>
    );
  } else if (type === 'toggle') {
    return (
      <div>
        <input
          type="checkbox"
          checked={audioEffects[effectName]}
          onChange={() => handleChange()}
          id={`toggle-${effectName}`}
        />
        <label htmlFor={`toggle-${effectName}`}>{label}</label>
      </div>
    );
  }

  return null; // Fallback für unbekannte Typen
};

export default AudioEffectControls;